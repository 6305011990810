import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import WhoWeAre from './pages/WhoWeAre';
import Projects from './pages/Projects';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Construction from './pages/Construction';
import Certification from './pages/Certification';
import Compliance from './pages/Compliance';
import Error from './pages/Error';
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import TopSection from "./components/TopSection";
import WhatsAppIcon from "./components/WhatsAppIcon";
import BackToTop from "./components/BackToTop";

function App() {
  return (
    <BrowserRouter>
    <TopSection></TopSection>
      <Nav></Nav>
       <Routes>
            <Route path='/' element={<Home></Home>}></Route>
            <Route path='about-us' element={<WhoWeAre></WhoWeAre>}></Route>
            <Route path='services' element={<Services></Services>}></Route>
             <Route path='contact' element={<Contact></Contact>}></Route>
             <Route path='*' element={<Error></Error>}></Route>
             {/* Footer Services Links */}
             <Route path='projects' element={<Projects></Projects>}></Route>
            <Route path='structural-assessments' element={<Construction></Construction>}></Route> 
            <Route path='regulatory-compliance' element={<Compliance></Compliance>}></Route>    
             <Route path='certificiation' element={<Certification></Certification>}></Route>       
             {/* Footer Services Links */}

       </Routes>
       <WhatsAppIcon></WhatsAppIcon><BackToTop></BackToTop>
       <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
