import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Design & Build | Geostruct Limited';
},[]);


  return (
    <>
    <section className='project-cover'>
        {/* <h2 className='home-h2 home-service-h2-a'>Recent Projects</h2> */}
        <div className='project-container'>
            <h3 className='title-h3 title-h3-a'> Proposed Modifications to a Section of NIC Building situated at
 Plot 3 Pilkington Road in Kampala</h3>
   <table className='team-table team-table1'>
    
    

    <tr>
     
      <td><strong>Client: </strong></td>
      <td>Nic Holdings Ltd</td>
    </tr>

    <tr>
      
      <td><strong>Initiating Consultant:</strong></td>
      <td>Eng. Victor Kyobe</td>
    </tr>

    <tr>
      <td><strong>Location:</strong></td>
      <td>Central Division of Kampala</td>
    </tr>

    <tr>
      <td><strong>Completion date:</strong></td>
      <td>November 2022</td>
    </tr>
    <tr>
      <td><strong>Scope of service:</strong></td>
      <td>Condition surveys and structural investigations</td>
    </tr>
    <tr>
      <td><strong>Some of the tests undertaken:</strong></td>
      <td>Pull-out tests, Rebound hammer tests, Ultrasonic
 Pulse Velocity tests, Cover meter scans and Rebar scans</td>
    </tr>

 </table>

        </div>

    {/* </section>
     <section className='project-cover project-cover1'> */}
        <div className='project-container'>
          <img src='./images/23.jpg' className='project-image'></img>  
        </div>

    </section>



    <section className='project-cover project-cover1'>
        <div className='project-container'>
            <h3 className='title-h3 title-h3-a'>  Proposed Modifications to a Section of Rwenzori Courts
 Building situated at Plots 2-4 Nakasero Road, Kampala</h3>
   <table className='team-table team-table1'>
    
    

    <tr>
     
      <td><strong>Client: </strong></td>
      <td>Rwenzori Courts Ltd/HL Construction Ltd</td>
    </tr>

    <tr>
      
      <td><strong>Initiating Consultant:</strong></td>
      <td>Eng. Sylvia N Mutungi</td>
    </tr>

    <tr>
      <td><strong>Location:</strong></td>
      <td>Central Division of Kampala</td>
    </tr>

    <tr>
      <td><strong>Completion date:</strong></td>
      <td>November 2022</td>
    </tr>
    <tr>
      <td><strong>Scope of service:</strong></td>
      <td>Condition surveys and structural investigations</td>
    </tr>
    <tr>
      <td><strong>Some of the tests undertaken:</strong></td>
      <td>Pull-out tests, Rebound hammer tests, Ultrasonic Pulse
 Velocity tests, Cover meter scans and Rebar scans</td>
    </tr>

 </table>

        </div>
{/* 
    </section>
     <section className='project-cover project-cover1'> */}
        <div className='project-container'>
          <img src='./images/24.jpg' className='project-image'></img>  
        </div>

    </section>
     <section className='project-cover'>
        <div className='project-container'>
            <h3 className='title-h3 title-h3-a'> Construction of a Multi-Purpose Four-Level Pharmacy House,
 Phase II (Part A) at Plot 1847 Block 220 Kyambogo</h3>
   <table className='team-table team-table1'>
    <tr>
     
      <td><strong>Client: </strong></td>
      <td>The Pharmaceutical Society of Uganda</td>
    </tr>

    <tr>
      
      <td><strong>Initiating Consultant:</strong></td>
      <td>Eng. Happy Moses Were</td>
    </tr>

    <tr>
      <td><strong>Location:</strong></td>
      <td>Nakawa Division of Kampala</td>
    </tr>

    <tr>
      <td><strong>Completion date:</strong></td>
      <td>October 2022</td>
    </tr>
    <tr>
      <td><strong>Scope of service:</strong></td>
      <td>Foundation audit, condition surveys and structural
 investigations</td>
    </tr>
    <tr>
      <td><strong>Some of the tests undertaken:</strong></td>
      <td>Rebound hammer tests, Ultrasonic Pulse Velocity tests,
 Cover meter scans, Rebar scans and DCP tests</td>
    </tr>

 </table>

        </div>

    {/* </section>
     <section className='project-cover project-cover1'> */}
        <div className='project-container'>
          <img src='./images/25.jpg' className='project-image'></img>  
        </div>

    </section>
         <section className='project-cover project-cover1'>
        <div className='project-container'>
            <h3 className='title-h3 title-h3-a'>  Integrity Assessment of Lotis Towers Situated At Plot 16 Army
 Avenue in Nakasero, Kampala</h3>
   <table className='team-table team-table1'>
    <tr>
     
      <td><strong>Client: </strong></td>
      <td>Lotigo Properties Ltd</td>
    </tr>

    <tr>
      
      <td><strong>Initiating Consultant:</strong></td>
      <td>Armstrong Consulting Engineers Ltd</td>
    </tr>

    <tr>
      <td><strong>Location:</strong></td>
      <td>Central Division of Kampala</td>
    </tr>

    <tr>
      <td><strong>Completion date:</strong></td>
      <td>August 2022</td>
    </tr>
    <tr>
      <td><strong>Scope of service:</strong></td>
      <td>Condition surveys and structural investigations</td>
    </tr>
    <tr>
      <td><strong>Some of the tests undertaken:</strong></td>
      <td>Foundation Audit, Rebound hammer tests, Ultrasonic
 Pulse Velocity tests, Cover meter scans, Rebar scans and
 DCP tests</td>
    </tr>

 </table>

        </div>

    {/* </section>
     <section className='project-cover project-cover1'> */}
        <div className='project-container'>
          <img src='./images/26.jpg' className='project-image'></img>  
        </div>

    </section>
        <section className='project-cover'>
        <div className='project-container'>
            <h3 className='title-h3 title-h3-a'>Assessment of Buildings in Uganda for Structural Integrity and
 Earthquake Resistance (Phase 1)</h3>
   <table className='team-table team-table1'>
    <tr>
     
      <td><strong>Client: </strong></td>
      <td>Ministry of Works and Transport</td>
    </tr>

    <tr>
      
      <td><strong>Initiating Consultant:</strong></td>
      <td>Armstrong Consulting Engineers Ltd</td>
    </tr>

    <tr>
      <td><strong>Location:</strong></td>
      <td>Kasese and Hoima Districts</td>
    </tr>

    <tr>
      <td><strong>Completion date:</strong></td>
      <td>August 2022</td>
    </tr>
    <tr>
      <td><strong>Scope of service:</strong></td>
      <td>Condition surveys, Structural and Geotechnical
 investigations for over 120 structures</td>
    </tr>
    <tr>
      <td><strong>Some of the tests undertaken:</strong></td>
      <td>Foundation audits, Destructive testing, Non-destructive
 testing such as Mortar strength tests, Rebound hammer
tests, Ultrasonic Pulse Velocity tests, Cover meter scans,
 Rebar scans and Geotechnical ground investigations</td>
    </tr>

 </table>

        </div>

    {/* </section>
     <section className='project-cover project-cover1'> */}
        <div className='project-container'>
          <img src='./images/27.jpg' className='project-image'></img>  
        </div>

    </section>
    <section className='project-cover project-cover1'>
        <div className='project-container'>
            <h3 className='title-h3 title-h3-a'> Uganda Free Zone of International Industrial Cooperation Project Uganda Free Zone of International Industrial Cooperation Project</h3>
   <table className='team-table team-table1'>
    <tr>
     
      <td><strong>Client: </strong></td>
      <td>Guangzhou Dongsong Energy
 Group (U) Co. Ltd</td>
    </tr>

    <tr>
      
      <td><strong>Initiating Consultant:</strong></td>
      <td>Buroni Services Ltd</td>
    </tr>

    <tr>
      <td><strong>Location:</strong></td>
      <td>Uganda Industrial Park in Tororo
 District</td>
    </tr>

    <tr>
      <td><strong>Completion date:</strong></td>
      <td>August 2022</td>
    </tr>
    <tr>
      <td><strong>Scope of service:</strong></td>
      <td>Condition surveys, Identification
	 	 	 	 of structural deficiencies, Structural
 and Geotechnical investigations
 for selected structures</td>
    </tr>
    <tr>
      <td><strong>Some of the tests undertaken:</strong></td>
      <td>Foundation audits, Destructive
 testing, Non-destructive testing
 such as Rebound hammer tests,
 Cover meter scans, Rebar scans,
 and geotechnical ground
 investigations</td>
    </tr>

 </table>

        </div>

    {/* </section>
     <section className='project-cover project-cover1'> */}
        <div className='project-container'>
          <img src='./images/28.jpg' className='project-image'></img>  
        </div>

    </section>
     <section className='project-cover'>
        <div className='project-container'>
            <h3 className='title-h3 title-h3-a'> Proposed Addition of One floor to an Existing Building at
 Vivo Energy Uganda HQ</h3>
   <table className='team-table team-table1'>
    <tr>
     
      <td><strong>Client: </strong></td>
      <td>Vivo Energy (U) Ltd</td>
    </tr>

    <tr>
      
      <td><strong>Initiating Consultant:</strong></td>
      <td>Eng. Happy Moses Were</td>
    </tr>

    <tr>
      <td><strong>Location:</strong></td>
      <td>7<sup>th</sup> Street Industrial Area in Kampala</td>
    </tr>

    <tr>
      <td><strong>Completion date:</strong></td>
      <td>March 2021</td>
    </tr>
    <tr>
      <td><strong>Scope of service:</strong></td>
      <td>Condition surveys, Structural and Geotechnical
 investigations 
</td>
    </tr>
    <tr>
      <td><strong>Some of the tests undertaken:</strong></td>
      <td>Foundation audits, Non-destructive testing such as
 Rebound hammer tests, Cover meter scans, Rebar scans,
 and geotechnical ground investigations</td>
    </tr>

 </table>

        </div>

    {/* </section>
     <section className='project-cover project-cover1'> */}
        <div className='project-container'>
          <img src='./images/29.jpg' className='project-image'></img>  
        </div>

    </section>
    <section className='project-cover project-cover1'>
        <div className='project-container'>
            <h3 className='title-h3 title-h3-a'>  Structural Assessment of the Meeting Hall Building
 at Escape Village Hotel located inYumbe District</h3>
   <table className='team-table team-table1'>
    <tr>
     
      <td><strong>Client: </strong></td>
      <td>Escape Village Hotel</td>
    </tr>

    <tr>
      
      <td><strong>Initiating Consultant:</strong></td>
      <td>Kigonya Ronald</td>
    </tr>

    <tr>
      <td><strong>Location:</strong></td>
      <td>Yumbe District</td>
    </tr>

    <tr>
      <td><strong>Completion date:</strong></td>
      <td>April 2021</td>
    </tr>
    <tr>
      <td><strong>Scope of service:</strong></td>
      <td>Condition surveys,
	 	 	 	 	 Identification of structural
	 	 	 	 	 deficiencies, Structural and
 Geotechnical investigations</td>
    </tr>
    <tr>
      <td><strong>Some of the tests undertaken:</strong></td>
      <td>Foundation audits, Non-
 destructive testing such as
Rebound hammer tests,
Cover meter scans, Rebar
scans, and geotechnical
ground investigations</td>
    </tr>

 </table>

        </div>

    {/* </section>
     <section className='project-cover project-cover1'> */}
        <div className='project-container'>
          <img src='./images/30.jpg' className='project-image'></img>  
        </div>

    </section>
    <section className='project-cover'>
        <div className='project-container'>
            <h3 className='title-h3 title-h3-a'>Partial Structural Audit of an Existing Commercial Building located at
 Plot 17A along William Street in Central Division of Kampala</h3>
   <table className='team-table team-table1'>
    <tr>
     
      <td><strong>Client: </strong></td>
      <td>Equity Bank (U) Ltd</td>
    </tr>

    {/* <tr>
      
      <td><strong>Initiating Consultant:</strong></td>
      <td>Central Division of Kampala</td>
    </tr> */}

    <tr>
      <td><strong>Location:</strong></td>
      <td>Central Division of Kampala</td>
    </tr>

    <tr>
      <td><strong>Completion date:</strong></td>
      <td>June 2021</td>
    </tr>
    <tr>
      <td><strong>Scope of service:</strong></td>
      <td>Evaluation of structural repairs 
</td>
    </tr>
    <tr>
      <td><strong>Some of the tests undertaken:</strong></td>
      <td>Non-destructive testing such as Rebound hammer tests,
 Ultrasonic testing, Cover meter scans and Rebar scans</td>
    </tr>

 </table>

        </div>

    {/* </section>
     <section className='project-cover project-cover1'> */}
        <div className='project-container'>
          <img src='./images/31.jpg' className='project-image'></img>  
        </div>

    </section>
     <section className='project-cover project-cover1'>
        <div className='project-container'>
            <h3 className='title-h3 title-h3-a'>Proposed Extension to Henrob Hospital
 Building in Zzana</h3>
   <table className='team-table team-table1'>
    <tr>
     
      <td><strong>Client: </strong></td>
      <td>Henrob Hospital Zzana</td>
    </tr>

    <tr>
      
      <td><strong>Initiating Consultant:</strong></td>
      <td>Akankwasa Wilson</td>
    </tr>

    <tr>
      <td><strong>Location:</strong></td>
      <td>Makindye Ssabagabo
 Municipal Council in Wakiso
 District</td>
    </tr>

    <tr>
      <td><strong>Completion date:</strong></td>
      <td>July 2021</td>
    </tr>
    <tr>
      <td><strong>Scope of service:</strong></td>
      <td>Quality control, Structural and
 Geotechnical investigations</td>
    </tr>
    <tr>
      <td><strong>Some of the tests undertaken:</strong></td>
      <td>Foundation audits, Non-destructive
 testing such as Rebound hammer
 tests, Cover meter scans, Rebar
 scans and geotechnical ground
 investigations</td>
    </tr>

 </table>

        </div>

    {/* </section>
     <section className='project-cover project-cover1'> */}
        <div className='project-container'>
          <img src='./images/32.jpg' className='project-image'></img>  
        </div>

    </section>
    <section className='project-cover'>
        <div className='project-container'>
            <h3 className='title-h3 title-h3-a'>As-built and Proposed Luxury Apartments at Plot 6A Mabua Road in
 Kololo, Central Division of Kampala</h3>
   <table className='team-table team-table1'>
    <tr>
     
      <td><strong>Client: </strong></td>
      <td>Jukas Construction Ltd</td>
    </tr>

    <tr>
      
      <td><strong>Initiating Consultant:</strong></td>
      <td>Eng. Jjingo Kizito</td>
    </tr>

    <tr>
      <td><strong>Location:</strong></td>
      <td>Central Division of Kampala</td>
    </tr>

    <tr>
      <td><strong>Completion date:</strong></td>
      <td>May 2020</td>
    </tr>
    <tr>
      <td><strong>Scope of service:</strong></td>
      <td>Structural investigations 
</td>
    </tr>
    <tr>
      <td><strong>Some of the tests undertaken:</strong></td>
      <td>Non-destructive testing such as Rebound hammer tests,
 Cover meter scans, Rebar scans</td>
    </tr>

 </table>

        </div>

    {/* </section>
     <section className='project-cover project-cover1'> */}
        <div className='project-container'>
          <img src='./images/33.jpg' className='project-image'></img>  
        </div>

    </section>
     <section className='project-cover project-cover1'>
        <div className='project-container'>
            <h3 className='title-h3 title-h3-a'>Proposed Extension to the Parking Structure at Social Security House</h3>
   <table className='team-table team-table1'>
    <tr>
     
      <td><strong>Client: </strong></td>
      <td>National Social Security Fund (NSSF)</td>
    </tr>

    <tr>
      
      <td><strong>Initiating Consultant:</strong></td>
      <td>Armstrong Consulting Engineers Ltd</td>
    </tr>

    <tr>
      <td><strong>Location:</strong></td>
      <td>Central Division of Kampala</td>
    </tr>

    <tr>
      <td><strong>Completion date:</strong></td>
      <td>September 2020</td>
    </tr>
    <tr>
      <td><strong>Scope of service:</strong></td>
      <td>Structural and Geotechnical investigations 
</td>
    </tr>
    <tr>
      <td><strong>Some of the tests undertaken:</strong></td>
      <td>Foundation audits, Non-destructive testing such as
 Rebound hammer tests, Cover meter scans, Rebar scans,
 and geotechnical ground investigations</td>
    </tr>

 </table>

        </div>

    {/* </section>
     <section className='project-cover project-cover1'> */}
        <div className='project-container'>
          <img src='./images/34.jpg' className='project-image'></img>  
        </div>

    </section>
</>
               

  )
}

export default Services