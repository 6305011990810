import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaTasks,FaHandshake,FaCheck,FaQuoteLeft, FaCogs} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {
  useEffect(()=>{
document.title = 'Geostruct Limited | Geotechnical and Structural Solutions';

},[]);
  return  <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover bg-cover1'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'><strong>Geostruct Limited</strong> is a Civil Engineering company based in Uganda; the company was launched in 2019. We are well equipped with the best tools and qualified staff who are duly registered with the relevant authorities.
</p>
<p className='home-p'>We do Structural Assessment/Investigations considering both non-destructive methods and destructive methods. In addition, we do Vibrations Measurements and Assessment of effects on Buildings. We also do Concrete Construction Inspections, Structural Designs, Geotechnical Investigations, Specialised Geotechnical Designs using MIDAS soil works and Plate load bearing tests.</p>
<p className='home-p'>We offer a wide array of structural and geotechnical solutions that primarily pivot in assessing
structural issues, evaluating structural performance, construction phase quality control and
geotechnical designs using a combination of the latest technologies and in-house expertise.
After years of experience, we also offer complete turnkey services ranging from ground
investigations through to detailed design and construction. </p>
 
        </div>

    </section>
    <section className='home-table-cover'>
      <h2 className="home-h2 team-h2 home-h2-a">Our Capabilities</h2>
      <div className='line1 line1-a'> <div className='line2'></div></div>
      <table className='team-table team-table1'>
    
    <tr>
      <th>S/No.</th>
      <th>Capabilities</th>
      <th>Available tests</th>
    </tr>

    <tr>
      <td>1.</td>
      <td>Structural analysis, design and evaluations</td>
      <td>Mortar Strength Tests</td>
    </tr>

    <tr>
      <td>2.</td>
      <td>Condition assessment of existing structures</td>
      <td>Pull off tests</td>
    </tr>

    <tr>
      <td>3.</td>
      <td>Structural Investigations</td>
      <td>Pull out tests</td>
    </tr>

    <tr>
      <td>4.</td>
      <td>Non-destructive testing techniques such
as pull off/pull out tests, ultrasonic testing,
penetration tests, rebound hammers among
others</td>
      <td>
        <ul>
      <li>  Rebound hammer tests</li>
<li>Ultrasonic Pulse Velocity tests</li>
<li>Penetration tests</li>
<li>Vibration measurements</li>
</ul>
</td>
    </tr>
    <tr>
      <td>5.</td>
      <td>Vibrations measurements and assessment of
effects</td>
      <td>Reinforcement scans Cover meter scans</td>
    </tr>
    <tr>
      <td>6.</td>
      <td>Geotechnical ground investigations</td>
      <td>
        <ul>
      <li>Soundness test/Delamination Survey </li>
<li>Concrete core cutting</li>
</ul>
      </td>
    </tr>

    <tr>
      <td>7.</td>
      <td>General construction and construction
supervision</td>
      <td>
        <ul>
      <li>SPT tests</li>
<li>DPL tests</li>
</ul>
      </td>
    </tr>

    <tr>
      <td>8.</td>
      <td>Strengthening and repairs</td>
      <td>
       DCP tests</td>
    </tr>

    

 </table>

    </section>
  <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.831),rgba(0, 0, 0, 0.831)), url("./images/2.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            {/* <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div> */}
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We offer Geotechnical and Structural Engineering solutions for commercial, multi-family and residential projects. Our mission is to provide quick and cost-effective Geotechnical and Structural Engineering solutions to our customers. We will work with you, your architect, or design team, to help attain your goals. Our expert team is made up of qualified registered structural engineers. Trust us for practical engineering solutions that will perform at the highest level and stand the test of time.
</p>
        </div>
    </section>
    
 {/* <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>Since we opened up a branch in Burundi we have been selling our products to REGIDESO BURUNDI, SOGEA SATOM BURUNDI and many other companies. Our products are standard of Shneider, Siemens and ABB from Turkey, Germany and the UK. </p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>




       </div>

        <div className='content-container goals-container goals-container1'>
           <article className='goals-text goals-text1'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Mission Statement</h2>
             <div className='line1'> <div className='line2'></div></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                        
                          <p className='goal-description'></p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>MALT ELECTRICAL ENGINEERING CO. LTD has expertise in installation of power lines, transformer repairs, substation maintenance, electrical installation and maintenance/upgrading of substation from 10KV-220KV, material supply as well as intelligence systems of any nature. </p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More so, we have a strong vendor relationship with most service-oriented vendors who are capable of shipping major parts or materials rapidly (on an overnight timeline in most cases) hence minimizing the system downtime for our customers. </p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Our customers rely on us for complete installation. Some repairs which we do can even be carried out directly at the site by our well-equipped field personnel in order to avoid the significant expenses of returning the equipment to the plant and save time.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>We work with our customers to develop a repair plan that goes beyond replacing worn out parts. The ultimate result is a replacement that could be better than the original one.</p>
                     </div>

                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Our company works in conjunction with different partners in Turkey, Germany, the UK, Kenya and China.</p>
                     </div>

                    
<Link to="about-us" className='home-btn'>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>




       </div>

    

  </section> */}

   <section className='home-service-cover'>
    {/* <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2> */}
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'> <div className='line2'></div></div>
   
    <div className='home-services-container '>
      {/* <div className='about-img-container'>
      <img src='./images/AWUKO1.jpg' alt='BENARD AWUKO' className='about-img'></img>
   </div> */}
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTasks></FaTasks></span>
          </div>
          <h3 className='home-service-title'>Service</h3>
          <p>We pride ourselves with the highest level of service. Whether the requirement is a small and simple project or a larger and more complex project, we deliver you with a professional Geotechnical and Structural Engineering services quickly and efficiently.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Reliability</h3>
          <p>We are proud to say that we can handle all of your project requirements from start to finish. We will be there every step of the way. We are committed to giving our clients our full comprehensive range of services in the most reliable manner and we will stay accountable all the time.  </p>

        </div>
        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTools></FaTools></span>
          </div>
          <h3 className='home-service-title'>Construction & Installation</h3>
          <p>We provide upto six (6) employment teams, tools and transport to handle survey, Right-of-Way, material supply & installation, optical fiber termination & testing, commissioning, acceptance and handover.</p>
        </div> */}
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCogs></FaCogs></span>
          </div>
          <h3 className='home-service-title'>Expertise</h3>
          <p>
           Our experienced team of engineers leverage modern technologies to help you mitigate technical and structural risks associated with construction. We are diligent in following a structured approach towards Geotechnical and Structural Engineering which we have exhibited over the years.
            </p>
        </div>

 {/* <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck>  </span>
          </div>
          <h3 className='home-service-title'>Excellence</h3>
          <p>Our focus is our customers "first and foremost" and to this end we offer an excellent friendly service. </p>
        </div> */}


        

        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Our Approach</h3>
          <p>Our dedication to Respect, Integrity, Diversity, and Reliability has been the path to success for our company, our employees, and our clients. Our strength lies in our ability to find solutions. </p>
        </div> */}

        
    
    </div>
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}


        </div>


    </div>



  </section>

   <section className='home-service-cover projects-links-cover'>
    {/* <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2> */}
     <h2 className='home-h2 home-service-h2'>Recent Projects</h2>
    <div className='line1 line1-b'> <div className='line2'></div></div>
   
    <div className='home-services-container home-services-container1'>
      {/* <div className='about-img-container'>
      <img src='./images/AWUKO1.jpg' alt='BENARD AWUKO' className='about-img'></img>
   </div> */}
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTasks></FaTasks></span>
          </div> */}
          <h3 className='home-service-title'>Structural Assessments</h3>
          {/* <p>We pride ourselves with the highest level of service. Whether the requirement is a small and simple project or a larger and more complex project, we deliver you with a professional Geotechnical and Structural Engineering services quickly and efficiently.</p> */}
                  <div className='projects-links-image-cover'>
                  <img src='./images/20.jpg' className='projects-links-image' alt='image'></img>
                      </div>
                      <Link to='structural-assessments' className='project-link'>See Projects</Link>
        
        </div>
       
        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <h3 className='home-service-title'>Design &<br></br> Build </h3>
          {/* <br></br> */}
          {/* <br></br> */}
          {/* <p>We are proud to say that we can handle all of your project requirements from start to finish. We will be there every step of the way. We are committed to giving our clients our full comprehensive range of services in the most reliable manner and we will stay accountable all the time.  </p> */}
<div className='projects-links-image-cover'>
<img src='./images/21.jpg' className='projects-links-image' alt='image'></img>
    </div>
         <Link to='#' className='project-link'>See Projects</Link>
        </div>
        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTools></FaTools></span>
          </div>
          <h3 className='home-service-title'>Construction & Installation</h3>
          <p>We provide upto six (6) employment teams, tools and transport to handle survey, Right-of-Way, material supply & installation, optical fiber termination & testing, commissioning, acceptance and handover.</p>
        </div> */}
<div className='home-service-box home-service-box-a'>
          {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCogs></FaCogs></span>
          </div> */}
          <h3 className='home-service-title'>Geotechnical Investigations</h3>
          {/* <p>
           Our experienced team of engineers leverage modern technologies to help you mitigate technical and structural risks associated with construction. We are diligent in following a structured approach towards Geotechnical and Structural Engineering which we have exhibited over the years.
            </p> */}
            <div className='projects-links-image-cover'>
            <img src='./images/22.jpg' className='projects-links-image' alt='image'></img>
         </div>
         <Link to='#' className='project-link'>See Projects</Link>
        </div>

 {/* <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck>  </span>
          </div>
          <h3 className='home-service-title'>Excellence</h3>
          <p>Our focus is our customers "first and foremost" and to this end we offer an excellent friendly service. </p>
        </div> */}


        

        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Our Approach</h3>
          <p>Our dedication to Respect, Integrity, Diversity, and Reliability has been the path to success for our company, our employees, and our clients. Our strength lies in our ability to find solutions. </p>
        </div> */}

        
    
    </div>
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}


        </div>


    </div>



  </section>
   
    
  
    </>
  
}

export default Home