import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = 'About Us | Geostruct Limited';

},[]);


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">About Us</h2>
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                          <h3 className='title-h3'>Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>To provide the state-of-the-art engineering services with passion, integrity and innovation.</p>
                          
                          <h3 className='title-h3'>Mission</h3>
                          <div className='vm-line'></div>
                          <p>Becoming a key player in transforming the engineering sector in Uganda.</p>

                    </article>

                    


                 </div>

                 <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                      <VisionCarousel></VisionCarousel>
                    </article>

                    <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           {/* <CoreValues></CoreValues> */}
           <ul className='values-list'>
        <li>   Integrity.</li> 
<li> Attention to detail.</li> 
<li>Innovation.</li>
<li>Accountabiliy.</li>
           </ul>

                    </article>


                 </div>
          </div>
    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container '>
         <h3 className='title-h3'>Key Personnel</h3>
<div className='vm-line'></div>

              
<p className='home-p'><strong>Eng. Bernard Wasswa</strong>, American Concrete Institute (ACI) Concrete Construction Special Inspector. Bernard is a registered Civil Engineer with Engineers Registration Board (ERB) and a Member of Uganda Institute of Professional Engineers (UIPE).</p>

 
        </div>

    </section>
    

    </>
  )
}

export default WhoWeAre