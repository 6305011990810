import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

const Team = () => {

useEffect(()=>{
document.title = 'Projects | Geostruct Limited';

},[]);

  return (
//     <section className='section-cover team-cover' >
//          <div className='content-container table-container'>
//            <h2 className="home-h2 team-h2">Projects</h2>




//   <table className='team-table team-table1'>
    
//     <tr>
//       <th>S/No.</th>
//       <th>Description</th>
//       <th>Client</th>
//     </tr>

//      <tr>
//       <td>1.  </td>
//       <td>Structural Design of a commercial building
// (warehouse) at plot 8 Robert Mugabe Road</td>
//       <td>Daudani Chulam and Haider Lalani Kemal</td>
//     </tr>
//     <tr>
//       <td>2.</td>
//       <td>Structural Design and Construction
// Supervision of a proposed factory building
// at plot 715 Block 156 Kiwenda
// </td>
//       <td>Tonnet Agro Engineering Co. Ltd</td>
//        </tr>

//     <tr>
//       <td>3.</td>
//       <td>Structural Audit of an Equity Bank Branch
// at Plot 17A William Street
// </td>
//       <td>Equity Bank (U) Ltd</td>
//        </tr>

//     <tr>
//       <td>4. </td>
//       <td>GI to support infrastructure Designs of 8
// Technical Institutes
// </td>
//       <td>Ministry of Education and Sports/Pan
// Modern Consult </td>
//       </tr>

//     <tr>
//       <td>5.</td>
//       <td>GI for proposed Oncology Factory at Cipla
// Quality Chemical Industries Ltd in Luzira
// </td>
//       <td>Cipla Quality Chemical Industries Ltd </td>

//     </tr>

//      <tr>
//       <td>6.</td>
//       <td>GI for surface water abstraction for Tilenga
// Project
// </td>
//       <td>Total Energies/Gauff Consultants (U) Ltd</td>

//     </tr>

//       <tr>
//       <td>7.</td>
//       <td>GI for proposed UNOC site office in Kabale
// Industrial Park, Hoima
// </td>
//       <td>Uganda National Oil Company</td>

//     </tr>

//      <tr>
//       <td>8.</td>
//       <td>GI for Proposed Reapers Shekinah Estate
// at Nakwero
// </td>
//       <td>Reapers Cooperative Society </td>

//     </tr>

//      <tr>
//       <td>9.</td>
//       <td>Structural Condition Assessment of UDB
// Towers at Plot 22 Hannington Road,
// Kampala
// </td>
//       <td>UDB/Armstrong
// Consulting Engineers Ltd</td>

//     </tr>

//     <tr>
//       <td>10.</td>
//       <td>Structural Assessment of the office building
// at Vivo Energy Head Office along 6th street
// Industrial Area
// </td>
//       <td>VIVO Energy/KAHL
// Consults Ltd</td>

//     </tr>

//      <tr>
//       <td>11.</td>
//       <td>Structural Designs for the Proposed
// Residential Apartments at Plot 1079 & 1080
// Block 257 Lukuli Road
// </td>
//       <td>Chebet Lillian</td>

//     </tr>

//       <tr>
//       <td>12.</td>
//       <td>Structural Designs for the Proposed
// Residential Building at Plot 738 Block 23
// Mengo, Kibuga in Rubaga Division
// </td>
//       <td>Mr. Tony Luyimbazi </td>

//     </tr>

//      <tr>
//       <td>13.</td>
//       <td>Structural Assessment of School Building of
// Mackay Memorial College in Nateete
// </td>
//       <td>Mackay Memorial
// College</td>

//     </tr>

//     <tr>
//       <td>14.</td>
//       <td>Structural Assessment of some of the
// structural components of Kibaale Town
// Supply System

// </td>
//       <td>MoWE-Central/KAHL
// Consults Ltd</td>

//     </tr>

//      <tr>
//       <td>15. </td>
//       <td>Structural Assessment of the Parking
// Structure at Social Security House

// </td>
//       <td>NSSF/Armstrong
// Consulting Engineers Ltd</td>

//     </tr>

//     <tr>
//       <td>16. </td>
//       <td>Vibrations surveys and assessment at UBL
// Luzira   

// </td>
//       <td>SEKA Associates/Teclab</td>

//     </tr>

//     <tr>
//       <td>17.</td>
//       <td>Vibration measurements and assessment at
// the Kitchen Block of Green Hill Academy
// Kibuli
// </td>
//       <td>GreenHill/Teclab</td>

//     </tr>

//     <tr>
//       <td>18.</td>
//       <td>Structural Assessment of the Conference
// Hall at Escape Village Hotel in Yumbe
// District
// </td>
//       <td>Escape Village Hotel</td>
//     </tr>

// <tr>
//       <td>19.</td>
//       <td>Geotechnical Investigations for Proposed
// Building Developments for Imvepi and
// Rhino Refugee Camps
// </td>
//       <td>KULIKA Uganda</td>
//     </tr>

//     <tr>
//       <td>20.</td>
//       <td>Soil Investigations at the Proposed
// Kyambogo University Central Lecture Block
// </td>
//       <td>Infrastructure
// Development and
// Management Ltd</td>
//     </tr>

//       <tr>
//       <td>21.</td>
//       <td>Geotechnical Investigations for Proposed
// Factory Structures at Plot 1701 Namanve
// Industrial Park
// </td>
//       <td>Ntake Bakery Services
// Ltd</td>
//     </tr>

//  </table>

//          </div>

           
//     </section>
   <section className='home-service-cover projects-links-cover'>
    {/* <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2> */}
     <h2 className='home-h2 home-service-h2-a'>Recent Projects</h2>
    {/* <div className='line1 line1-b'> <div className='line2'></div></div> */}
   
    <div className='home-services-container home-services-container1'>
      {/* <div className='about-img-container'>
      <img src='./images/AWUKO1.jpg' alt='BENARD AWUKO' className='about-img'></img>
   </div> */}
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTasks></FaTasks></span>
          </div> */}
          <h3 className='home-service-title'>Structural Assessments</h3>
          {/* <p>We pride ourselves with the highest level of service. Whether the requirement is a small and simple project or a larger and more complex project, we deliver you with a professional Geotechnical and Structural Engineering services quickly and efficiently.</p> */}
                  <div className='projects-links-image-cover'>
                  <img src='./images/20.jpg' className='projects-links-image' alt='image'></img>
                      </div>
                      <Link to='/structural-assessments' className='project-link'>See Projects</Link>
        
        </div>
       
        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <h3 className='home-service-title'>Design & Build</h3>
          <br></br>
          {/* <br></br> */}
          {/* <p>We are proud to say that we can handle all of your project requirements from start to finish. We will be there every step of the way. We are committed to giving our clients our full comprehensive range of services in the most reliable manner and we will stay accountable all the time.  </p> */}
<div className='projects-links-image-cover'>
<img src='./images/21.jpg' className='projects-links-image' alt='image'></img>
    </div>
         <Link to='#' className='project-link'>See Projects</Link>
        </div>
        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTools></FaTools></span>
          </div>
          <h3 className='home-service-title'>Construction & Installation</h3>
          <p>We provide upto six (6) employment teams, tools and transport to handle survey, Right-of-Way, material supply & installation, optical fiber termination & testing, commissioning, acceptance and handover.</p>
        </div> */}
<div className='home-service-box home-service-box-a'>
          {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCogs></FaCogs></span>
          </div> */}
          <h3 className='home-service-title'>Geotechnical Investigations</h3>
          {/* <p>
           Our experienced team of engineers leverage modern technologies to help you mitigate technical and structural risks associated with construction. We are diligent in following a structured approach towards Geotechnical and Structural Engineering which we have exhibited over the years.
            </p> */}
            <div className='projects-links-image-cover'>
            <img src='./images/22.jpg' className='projects-links-image' alt='image'></img>
         </div>
         <Link to='#' className='project-link'>See Projects</Link>
        </div>

 {/* <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck>  </span>
          </div>
          <h3 className='home-service-title'>Excellence</h3>
          <p>Our focus is our customers "first and foremost" and to this end we offer an excellent friendly service. </p>
        </div> */}


        

        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Our Approach</h3>
          <p>Our dedication to Respect, Integrity, Diversity, and Reliability has been the path to success for our company, our employees, and our clients. Our strength lies in our ability to find solutions. </p>
        </div> */}

        
    
    </div>
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}


        </div>


    </div>



  </section>
  )
}

export default Team